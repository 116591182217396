<template>
  <div>
    <div class="container is-steps">
      <section>
        <b-steps
          :has-navigation="hasNavigation"
          v-model="activeStep"
          type="is-info"
        >
          <b-step-item label="Create Company" :icon="iconStep1">
            <InputCompany @toNextStep="nextStep" />
          </b-step-item>
          <b-step-item label="Choose Package" :icon="iconStep2">
            <ChoosePackage @choosePackage="nextStep($event)" />
          </b-step-item>
          <b-step-item label="Payment" icon="currency-usd-circle-outline">
            <PaymentDetail :data="datas" @toNextStep="nextStep" />
          </b-step-item>
          <b-step-item label="Done" icon="script-text-outline">
            <DonePayment />
          </b-step-item>
        </b-steps>
      </section>
    </div>
    <div class="is-footer">
      <div class="container">
        <div class="is-flex is-justify-content-space-between mt-4">
          <div>
            <div class="is-flex">
              <b-icon
                class="mt-2"
                icon="email-outline"
                size="is-small"
              ></b-icon>
              <a class="email-footer ml-1" :href="'mailto:' + emailAddress">
                {{ emailAddress }}
              </a>
            </div>
            <p class="text-footer">For payments & billing questions</p>
          </div>

          <div class="is-flex mt-3">
            <b-icon class="mt-2" icon="phone-outline" size="is-small"></b-icon>
            <a class="phone-footer" :href="'tel:' + phoneNumber">
              {{ phoneNumber }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import InputCompany from './components/InputCompany.vue'
import ChoosePackage from './components/ChoosePackage.vue'
import PaymentDetail from './components/PaymentDetail.vue'
import DonePayment from './components/DonePayment.vue'
export default {
  components: {
    InputCompany,
    ChoosePackage,
    PaymentDetail,
    DonePayment,
  },
  data() {
    return {
      datas: null,
      phoneNumber: '+628112221483',
      emailAddress: 'hello@getrise.id',
      activeStep: 0,

      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: false,
      isProfileSuccess: false,
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      iconStep1: 'office-building',
      iconStep2: 'archive-outline',
    }
  },

  mounted() {
    if (this.$route.query.order) {
      this.activeStep = 3
    }
  },

  watch: {
    activeStep() {
      if (this.activeStep > 0) {
        this.iconStep1 = 'check'
      } else {
        this.iconStep1 = 'office-building'
      }

      if (this.activeStep > 1) {
        this.iconStep2 = 'check'
      } else {
        this.iconStep2 = 'archive-outline'
      }
    },
  },

  methods: {
    convertToCurrency(amount) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
      })
      return formatter.format(amount)
    },
    nextStep(packageId) {
      this.getPackageDetail(packageId)
      this.activeStep += 1
    },
    async getPackageDetail(id) {
      try {
        const response = await axios.post(
          '/api/space-roketin/payment/v1/package/subscribe',
          { package_id: id }
        )
        this.datas = response.data.data
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style></style>
