<template>
  <div class="is-justify-content-center is-flex">
    <div class="is-form-create-company">
      <h1>Create Your Company</h1>
      <p class="write-down-text">Write down your company name</p>
      <form class="input-company-name" action="">
        <b-field label="Company Name">
          <b-input v-model="name"></b-input>
        </b-field>
        <div class="buttons">
          <b-button
            :disabled="!name"
            @click="toNextStep()"
            type="is-primary"
            expanded
          >
            <span :class="!name ? 'inactive-text-btn' : 'text-btn'">
              Create
            </span>
          </b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
    }
  },
  methods: {
    toNextStep() {
      this.$emit('toNextStep')
      localStorage.setItem('name', this.name)
    },
  },
  mounted() {
    if (localStorage.getItem('name')) {
      this.name = localStorage.getItem('name')
    }
  },
}
</script>

<style></style>
