<template>
  <div class="done-payment-page">
    <div class="has-text-centered">
      <h1 class="done-payment-title">Payment Successful</h1>
    </div>
    <div class="done-payment-card mx-auto">
      <div class="card-header level p-5">
        <div class="level-left">
          <h5>Order Details :</h5>
        </div>

        <div class="level-right">
          <h5>{{ datas.invoiceCode }}</h5>
        </div>
      </div>

      <div class="px-5 pb-4 columns">
        <div class="column is-three-fifths">
          <div class="is-flex is-justify-content-space-between">
            <ul>
              <li
                class="left-text mb-2"
                v-for="(order, id) in orderKey"
                :key="id"
              >
                {{ order }}
              </li>

              <li class="right-text mt-5">Method Payment</li>
            </ul>
            <ul>
              <li class="right-text mb-2">: #{{ datas.code || '-' }}</li>
              <li class="right-text mb-2">: {{ datas.buyerName || '-' }}</li>
              <li class="right-text mb-2">: {{ datas.paymentDate || '-' }}</li>
              <li class="right-text mb-2">: {{ datas.billingDate || '-' }}</li>

              <li class="left-text mt-5 mb-5">
                : {{ datas.paymentMethod || '-' }}
              </li>
            </ul>
          </div>
          <a @click.prevent="toReceipt()" class="download-receipt" href="">
            <b-icon icon="file-link-outline" size="is-small"></b-icon>
            Download Receipt
          </a>
        </div>
        <div class="column">
          <div class="is-flex is-justify-content-flex-end">
            <div>
              <h3 class="subscribe-text">Subscribe Rise</h3>
              <h3
                class="package-name"
                v-for="(val, id) in datas.items"
                :key="'package-' + id"
              >
                Package : {{ val.name }}
              </h3>
            </div>
          </div>

          <div
            class="is-flex is-justify-content-space-between px-6 is-justify-content-flex-end mt-6"
          >
            <ul>
              <li class="subscribe-text">Total</li>
              <li
                class="subscribe-text"
                v-for="(val, id) in datas.items"
                :key="'discount-' + id"
              >
                Discount {{ val.discountAmount }}%
              </li>
              <li
                class="subscribe-text"
                v-for="(val, id) in datas.vouchers"
                :key="'voucher-' + id"
              >
                Voucher {{ val.discount }}%
              </li>
              <li
                class="subscribe-text"
                v-for="(val, id) in datas.items"
                :key="id"
              >
                Tax {{ val.taxPercentage }}%
              </li>
              <!-- <li class="subscribe-text">Tax %</li> -->
            </ul>
            <ul v-for="(val, id) in summary" :key="id">
              <li class="total-right-text">
                {{ formatCurrency(val.pricePackage) }}
              </li>
              <li class="total-right-text">
                - {{ formatCurrency(val.showDiscount) }}
              </li>
              <li class="total-right-text" v-if="voucherAmount">
                - {{ formatCurrency(voucherAmount) }}
              </li>
              <li class="total-right-text">+ {{ formatCurrency(tax) }}</li>
            </ul>
          </div>

          <div
            class="is-flex is-justify-content-space-between px-6 is-justify-content-flex-end mt-5"
          >
            <ul>
              <li class="subscribe-text">Grand Total</li>

              <!-- <li class="subscribe-text">Tax %</li> -->
            </ul>
            <ul>
              <li
                class="total-right-text"
                v-for="(val, id) in datas.items"
                :key="id"
              >
                {{ formatCurrency(countSubtotal()) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="buttons px-5 pb-5">
        <b-button @click="toDashboard()" type="is-primary" expanded>
          Go to Dashboard
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/common/api.service'
export default {
  data() {
    return {
      summary: null,
      orderKey: ['Order ID', 'Buyer', 'Date Payment', 'Billing Date Issue'],
      datas: null,
      vouchers: null,
      voucherAmount: null,
      tax: 0,
      discountedPrice: 0,
      priceAfterVoucher: 0,
    }
  },
  mounted() {
    this.syncData()
  },
  methods: {
    toReceipt() {
      this.$router.push({
        path: '/receipt',
        query: { order: this.$route.query.order },
      })
    },
    formatCurrency(value) {
      return `Rp ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    },
    async syncData() {
      let formData = new FormData()
      formData.append('code', this.$route.query.order)
      const response = await ApiService.post(
        `/api/space-roketin/payment/v1/payment/summary/${this.$route.query.order}`,
        formData
      )
      if (response.status == 200) {
        this.datas = response.data.data
        this.summary = response.data.data.items.map((res) => {
          console.log(res)
          return {
            taxPercentage: res.taxPercentage,
            pricePackage: res.pricePackage,
            finalPrice: res.finalPrice,
            taxAmount: res.taxAmount,
            showDiscount: res.showDiscount,
          }
        })
        this.vouchers = response.data.data.vouchers.map((res) => {
          return {
            voucherDiscount: res.discount,
          }
        })
        this.voucherAmount =
          (this.summary[0].pricePackage - this.summary[0].showDiscount) /
          this.vouchers[0]?.voucherDiscount
        // this.getPaymentDetail(response.data.data.code)
        this.discountedPrice =
          this.summary[0].pricePackage - this.summary[0].showDiscount
        this.priceAfterVoucher =
          this.discountedPrice - (this.voucherAmount ? this.voucherAmount : 0)
        this.tax =
          this.priceAfterVoucher * (this.summary[0]?.taxPercentage / 100)
      }
    },
    countSubtotal() {
      return (
        this.summary[0].pricePackage -
        this.summary[0].showDiscount -
        (this.voucherAmount ? this.voucherAmount : 0) +
        this.tax
      )
    },
    // async getPaymentDetail(code) {
    //   const response = await ApiService.get(
    //     `/api/space-roketin/payment/v1/payment/${code}`
    //   )
    // },
    toDashboard() {
      this.$router.push('/rise')
    },
  },
}
</script>

<style></style>
